@use 'sass:math';
/**
 * CSS relating to the ability to print a page. This includes
 * page break classes and print-only classes.
 */

.break-inside-avoid {
  break-inside: avoid;
}
.break-after-avoid {
  break-after: avoid;
}
.break-after-avoid-page {
  break-after: avoid-page;
}
.break-before {
  break-before: always;
}
.break-after {
  break-after: always;
}

.page-break-avoid {
  page-break-inside: avoid;
}
.page-break-after {
  page-break-after: always;
}
.page-break-before {
  page-break-before: always;
}

/**
 * Bootstrap treats the print resolution as pretty tiny (an XS screen)
 * so we need to override this to make it more readable.
 */
@media print {
  @for $i from 1 through 12 {
    // Using a duplicate selector here means that the CSS will prioritize
    // using the lg breakpoint if other sizes are specified, but will fall
    // back to the other sizes if the lg breakpoint is not specified.
    // The !important is needed in all cases to overcome bootstraps
    // default size queries
    .col-lg-#{$i}.col-lg-#{$i} {
      width: math.div(percentage(round($i * 8.33)), 100) !important;
      float: left;
    }
    .col-md-#{$i} {
      width: math.div(percentage(round($i * 8.33)), 100) !important;
      float: left;
    }
    .col-sm-#{$i} {
      width: math.div(percentage(round($i * 8.33)), 100) !important;
      float: left;
    }
  }

  .position-fixed-print {
    position: fixed;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
