@import 'colors.scss';
/**
 * A table with white space between colored cells.
 */

.table-colored-spaced {
  --cell-color: #{$lime-93};
  --heading-cell-color: #{$cc-lime};
  --background-opacity: 0.1;
  --gap-size: 0.25rem;
  --padding: 0.5rem;

  border-spacing: calc(var(--gap-size) * 2);
  border-collapse: separate;

  th {
    background-color: var(--heading-cell-color);
  }

  td {
    background-color: var(--cell-color);
  }

  td,
  th {
    padding: var(--padding);
  }

  tfoot tr td {
    background-color: transparent;
  }

  .blank::before {
    opacity: 0;
  }
}
