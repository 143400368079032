// Our colour palette is inspired by the colours of native birds.
// The primary pallette is split into the 3 key product divisions
// The native CCU Pallette is designed to grow and expand as we move to
// different markets.

// CarbonCrop Colors
$cc-lime: rgb(209, 229, 0);
$cc-pink: rgb(248, 178, 232);
$cc-offwhite: rgb(255, 252, 245); // Digital or paper stock match only
$cc-charcoal: rgb(51, 53, 63); // hsl(230, 10.5, 22.4)

// Tokens
$nzu-orange: rgb(255, 103, 31);
$nzu-blue: #00bee0;

// CCU's
$ccu-aquamarine: rgb(128, 206, 179);
$ccu-rose: rgb(250, 97, 97);

// Secondary Colors
$sec-fawn: rgb(194, 178, 135);
$sec-kakariki: rgb(166, 25, 70);
$sec-yellowhead: rgb(242, 239, 65);
$sec-tui: rgb(255, 170, 0);
$sec-birch: rgb(222, 228, 235); // hsl(212, 24.5, 89.6)
$sec-parakeet: rgb(40, 223, 102);
$sec-kokako: rgb(35, 20, 183);
$sec-kiwi: rgb(154, 89, 57);
$sec-loro: rgb(170, 25, 185);
$sec-deep-bush: rgb(22, 58, 57);

// Named by the approximate lightness in HSL space
$grey-15: #212126;
$grey-22: #33353f; // cc-charcoal
$grey-30: #444855;
$grey-40: #5c6170;
$grey-50: #757b8a;
$grey-60: #89909f;
$grey-70: #a8b0bd;
$grey-80: #c3cbd5;
$grey-90: #dee4eb; // cc-birch
$grey-95: #eef1f6;

$nzu-blue-93: #e8f4f3;

$lime-25: #758000;
$lime-35: #a4b300;
$lime-45: #d2e600; // cc-lime
$lime-70: #e6f075;
$lime-85: #f1f5bc;
$lime-93: #f9f9e2;

$pink-95: #fde8f8;
$pink-90: #fbd0f1;
$pink-84: #f8b4e8; // cc-pink
$pink-70: #d98cc7;
$pink-55: #af6a9f;

$theme-colors: (
  'primary': $cc-lime,
  'secondary': $grey-80,
  'success': $sec-parakeet,
  'danger': $sec-kakariki,
  'warning': $sec-tui,
  'info': $sec-deep-bush,
  'dark': $cc-charcoal,
  'light': $cc-offwhite,
  'random': $sec-deep-bush,
  'grey': $sec-birch,
);

$category-colors-v2: (
  'existing_indigenous_ets_forest': $nzu-blue,
  'future_native_planting': lighten($nzu-blue, 10%),
  'existing_exotic_ets_forest': $nzu-orange,
  'future_exotic_planting': lighten($nzu-orange, 10%),
  'potential_ets_forest': darken($cc-pink, 20%),
  'scattered_forest': $cc-pink,
  'pre_1990_forest': $sec-birch,
  'pasture_no_forest': $cc-offwhite,
  'native_carboncrop': $ccu-aquamarine,
);

$report-colors: (
  'carboncrop_holder': #7e7e7e,
);

// Other
$inactive-grey: #ccc;
