@import './colors.scss';

// Radius of the menu panels
$spacer: 1em;

// Bootstrap configuration variables
$btn-active-bg-tint-amount: 50%;
$btn-hover-bg-tint-amount: 50%;
$font-family-base: 'Poppins', sans-serif;

$form-label-margin-bottom: div($spacer, 4);

.progress-bar {
  background-color: $cc-lime;
}

h2 {
  font-weight: 300;
}
.card-circled {
  border: 1px solid $grey-60 !important;
}
